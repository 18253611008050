import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    editable: {
      display: 'inline-block',
      border: '1px solid transparent',

      '&:hover, &:focus': {
        borderColor: theme.palette.grey.A100,
      },
    },
  };
});
