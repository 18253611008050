import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => {
  return {
    option: {
      display: 'grid',
      gridTemplateColumns: 'minmax(50px, auto) min-content',
      columnGap: theme.spacing(0.5),
      width: '100%',
      maxWidth: '100%',
      overflow: 'hidden',
    },
    employeeSelect: {
      padding: '2px !important',
    },
    employeeSelectPaper: {
      minWidth: 300,
    },
    footer: {
      '& td': {
        fontSize: '1rem',
        fontWeight: 500,
        color: theme.palette.text.primary,
      },
    },
    rateTitle: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      borderRadius: 4,
      fontSize: 12,
      padding: '1px 1px',
    },
    table: {
      display: 'grid',
      gridTemplateColumns: 'minmax(200px, 1fr) 2fr 100px 1fr 60px',
      minWidth: '100%',

      '& thead, & tbody, & tfoot, & tr': {
        display: 'contents',
      },
      '& th, & td': {
        padding: theme.spacing(0.4),
        overflow: 'hidden',
        whiteSpace: 'nowrap',
      },
      '& th': {
        position: 'sticky',
        top: 0,
        backgroundColor: theme.palette.grey['100'],
        whiteSpace: 'normal',
      },
    },
    cell: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    tableWithSplitHours: {
      gridTemplateColumns: 'minmax(200px, 1fr) 75px 2fr 100px 1fr 1fr 1fr 60px',
    },
    tableWithSplitSalary: {
      gridTemplateColumns:
        'minmax(200px, 1fr) 75px minmax(200px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) minmax(50px, 1fr) 60px',

      '& th, & td': {
        fontSize: 13,
      },
    },
    formControl: {
      flex: 1,
    },
    condensed: {
      '& .MuiOutlinedInput-inputMarginDense, & .MuiSelect-root': {
        padding: theme.spacing(0.85),
      },
    },
    selectWithRate: {
      '& * $option': {
        // marginRight: theme.spacing(1),
        gridTemplateColumns: 'minmax(100px, auto) 0',
      },
    },
    small: {
      '& .MuiInputBase-input': {
        padding: theme.spacing(0.85),
      },
    },
    smallWithAddons: {
      '& .MuiInputBase-input': {
        padding: theme.spacing(0.85, 0, 0.85),
      },
    },
    textAlignRight: {
      '& .MuiOutlinedInput-input': {
        textAlign: 'right',
      },
    },
  };
});
