import cn from 'classnames';
import React, { ReactElement } from 'react';
import NumberFormat from 'react-number-format';

import { ShowNumberProperties } from '../../../types/components/common/ShowNumber';
import { useStyles } from './styles';

export const ShowNumber = ({ number, suffix, prefix, style }: ShowNumberProperties): ReactElement => {
  const classes = useStyles();

  return (
    <NumberFormat
      value={number}
      style={style}
      displayType="text"
      thousandSeparator
      decimalScale={2}
      fixedDecimalScale
      suffix={suffix}
      prefix={prefix}
      className={cn({
        [classes.negative]: number < 0,
      })}
    />
  );
};
