import { GoogleSpreadsheetWorksheet } from 'google-spreadsheet';
import { VariantType } from 'notistack';

import theme from '../theme';

export const globalTechProjectID = 74;
export const linkedProjectFieldId = 20;
export const appTitle = 'S&F Financial App';
export const precision = 5;
export const resourceGuruT = 'resourceGuru';
export const redmineT = 'redmine';
export const redmineLimit = 100;
export const redmineImportLimit = 1000;
export const resourceGuruLimit = 100;
export const resourceGuruCheckMonths = 3;
export const isProduction = process.env.NODE_ENV === 'production';
export const syncRedmineEntities = ['time_entry', 'issue'];
export const syncResourceGuruEntities = ['booking'];
export const defaultLdapServer = 'ldap.gluzdov.com';
export const billableProjectStates = ['yes', 'other'];
export const clientChannels = ['direct', 'partner'];
export const experienceVariants: Record<string, string> = {
  junior: 'Junior Developer',
  middle: 'Middle Developer',
  senior: 'Senior Developer',
};
export const usBasedTeamRole = 'US based employee';
export const FixBidCalculatorConfig = {
  teamRoles: [
    { role: 'Freelancer', control: 'rate' },
    { role: 'Junior Developer', control: 'rate' },
    { role: 'Middle Developer', control: 'rate' },
    { role: 'Senior Developer', control: 'rate' },
    { role: usBasedTeamRole, control: 'rate' },
    { role: 'Product manager', control: 'employeeSelect' },
    { role: 'Project Lead', control: 'employeeSelect' },
    { role: 'Coordinator', control: 'employeeSelect' },
    { role: 'DevOps', control: 'employeeSelect' },
    { role: 'QA', control: 'employeeSelect' },
    { role: 'Developer', control: 'employeeSelect' },
  ],
};
export const defaultSelectValue = `<Select>`;
export const SALARY_TYPE_V1 = 'v1';
export const SALARY_TYPE_V2 = 'v2';
export const SALARY_TYPE_ERROR = 'Salary Type is required';
export const RATE_V1_ERROR = 'Rate v1 is required';
export const RATE_V2_ERROR = 'Rate v2 is required';
export const STAKE_ERROR = 'Stake is required';
export const EMPLOYMENT_TYPE_ERROR = 'Type of Employment is required';
export const MINIMUM_ERROR = 'Minimum for Rate v2 is required';

export const snackbarOptions = {
  success: {
    variant: 'success' as VariantType,
    autoHideDuration: 3500,
  },
  error: {
    variant: 'error' as VariantType,
    autoHideDuration: null,
  },
};

export const productionUrl = 'http://forecasting-v2.gluzdov.com';
export const isStaging = process.env.IS_STAGING === 'true';
export const forecastingDatabaseName = process.env.FORECASTING_DB_NAME || 'sf-report-prod';

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const colorChangeOptions = {
  higher: 'limegreen',
  lower: 'crimson',
  duration: 5000,
};

export const accessForbiddenMessage = 'You must be sign in to view the protected content on this page.';

export const editableOriginalType = 'original';
export const editableCustomType = 'custom';

export const employeeHistoryRegex =
  /^history\.(rate|salaryType|rate2|stake|employmentType|experience)\.(\d{4}-\d{2}-\d{2})$/;
export const projectHistoryRegex = /^history\.(billableType)\.(\d{4}-\d{2}-\d{2})$/;

export const defaultProgressBarOptions = {
  size: 2,
  color: theme.palette.secondary.main,
  className: 'bar-of-progress',
  delay: 100,
};

export const redmineProjectSettingsTrackerId = 22;
export const redmineDepartmentPropertyId = 37;
export const redmineIsDepartmentId = 105;
export const redmineProjectLeadPropertyId = 38;
export const accountManager = ['@julia.manzo', '@ellen_svejin', '@irena.mali'].join(', ');
export const customFieldDepTech = 20;

export enum Roles {
  Admin = 'Admin',
  User = 'User',
  DepManager = 'Department Manager',
  ProjectLead = 'Project Lead',
}

export enum Departments {
  Enterprise = 759, // ENTERPRISE DEPARTMENT
  Solution = 953, // SOLUTIONS DEPARTMENT
  CMS = 756, // CMS DEPARTMENT
  Fullstack = 758, // FULLSTACK DEPARTMENT
  OldSolutions = 757, // OLD SOLUTIONS DEPARTMENT
  ProcessImprovements = 760, // PROCESS IMPROVEMENTS DEPARTMENT
  QAPractice = 69, // QA PRACTICE
  OperationsBranch = 752, // OPERATIONS BRANCH
  SalesBranch = 751, // SALES BRANCH
  ProjectManagementPractice = 790, // PROJECT MANAGEMENT PRACTICE
  DevOpsAndITPractice = 878, // DEVOPS AND IT PRACTICE
  DeliveryBranch = 753, // DELIVERY BRANCH

  SolutionsDepartmentClientProjects = 954, // Solutions Department Client Projects
  EnterpriseDepartmentClientProjects = 795, // Enterprise Department Client Projects
  CMSDepartmentClientProjectsClosed = 763, // CMS Department Client Projects (CLOSED)
  FullstackDepartmentClientProjectsClosed = 777, // Fullstack Department Client Projects (CLOSED)
  OldSolutionsClientProjects = 770, // Old Solutions Client Projects(CLOSED)
}
export const textFormatByValue = (row: number, column: number, value: number, sheet: GoogleSpreadsheetWorksheet) => {
  const textFormatRed = { foregroundColor: { red: 1, green: 0, blue: 0, alpha: 1 } };

  if (value < 0) {
    return (sheet.getCell(row, column).textFormat = textFormatRed);
  }
};

export enum DepTechProjects {
  EnterpriseDepTech = 796,
  FullstackDepTech = 778,
  CMSDepTech = 764,
  SolutionsDepTech = 955,
}
export enum PreSalesProjects {
  ConversionSolutionAndSolutionsPreSales = 1073,
  SolutionsPreSales = 964,
  EnterprisePreSales = 797,
  CMSPreSales = 765,
  OldSolutionsPreSales = 772,
  FullstackPreSales = 779,
}

export const isDepTechProject = (projectId: number): boolean => Object.values(DepTechProjects).includes(projectId);
export const isGlobalTechProject = (projectId: number): boolean => projectId === globalTechProjectID;
export const isPreSalesProject = (projectId: number): boolean => Object.values(PreSalesProjects).includes(projectId);
