import { TextField } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import Switch from '@material-ui/core/Switch';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableFooter from '@material-ui/core/TableFooter';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import UpdateIcon from '@material-ui/icons/Update';
import Autocomplete from '@material-ui/lab/Autocomplete';
import cn from 'classnames';
import React, { FC, useCallback, useRef } from 'react';
import NumberFormat from 'react-number-format';
import useColorChange from 'use-color-change';

import { colorChangeOptions, FixBidCalculatorConfig } from '../../../config';
import { filterByRole } from '../../../server/utils/filterList';
import { RateType, TeamMembersProperties } from '../../../types/components/TeamMembers';
import { round } from '../../../utils';
import { Editable } from '..';
import { useStyles } from './styles';

const SalaryCell = <T extends Types.Components.Common.TeamMembers.SalaryCellProperties>({ salary }: T): JSX.Element => {
  const salaryColorStyle = useColorChange(Number(salary), colorChangeOptions);

  return (
    <NumberFormat
      key={salary}
      thousandSeparator
      value={salary}
      displayType="text"
      decimalScale={2}
      fixedDecimalScale
      prefix="$"
      style={salaryColorStyle}
    />
  );
};

const NumberCell = <T extends Types.Components.Common.TeamMembers.NumberCellProperties>({
  number,
  decimalScale = 2,
}: T): JSX.Element => {
  const numberColorStyle = useColorChange(Number(number), colorChangeOptions);

  return (
    <NumberFormat
      decimalScale={decimalScale}
      thousandSeparator
      value={number}
      displayType="text"
      fixedDecimalScale
      style={numberColorStyle}
    />
  );
};

const RoleRate = <T extends Types.Components.Common.TeamMembers.RoleRateProperties>({
  rate,
  precision,
}: T): JSX.Element => {
  const rateColorStyle = useColorChange(Number(rate), colorChangeOptions);

  return (
    <NumberFormat
      key={rate}
      thousandSeparator
      value={rate}
      displayType="text"
      decimalScale={precision}
      prefix="$"
      style={rateColorStyle}
    />
  );
};

export const TeamMembers: FC<TeamMembersProperties> = ({
  team,
  employees,
  onDeleteTeamMember,
  onChangeRole,
  onChangeEmployee,
  onChangeHours,
  onChangeCustomRate,
  onRemoveCustomRate,
  onSetRateType,
  billableHours,
  absorbedHours,
  totalBillableSalary,
  totalGTSalary,
  totalHours,
  totalSalary,
  experiences,
  precision,
  splitHours,
  splitSalary,
  needsRateType,
}) => {
  const classes = useStyles();
  const references = useRef([]);
  const totalHoursColorStyle = useColorChange(totalHours, colorChangeOptions);
  const totalSalaryColorStyle = useColorChange(totalSalary, colorChangeOptions);
  const billableHoursColorStyle = useColorChange(Number(billableHours), colorChangeOptions);
  const absorbedHoursColorStyle = useColorChange(Number(absorbedHours), colorChangeOptions);
  const changeRateType = useCallback(
    ({ teamMemberId, checked }) => {
      if (!onSetRateType) {
        return;
      }

      if (checked) {
        onSetRateType({ teamMemberId, rateType: RateType.usBillableHours });
      } else {
        onSetRateType({ teamMemberId, rateType: RateType.euBillableHours });
      }
    },
    [team],
  );

  if (team.length === 0) {
    return null;
  }

  const renderTeamMemberRole: FC<{ role: string }> = ({ role }) => (
    <MenuItem key={role} value={role}>
      <div className={classes.option}>
        <div>{role}</div>
        <div>
          {experiences[role] && (
            <span title="Average Rate" className={classes.rateTitle}>
              <RoleRate rate={experiences[role]} precision={precision} />
            </span>
          )}
        </div>
      </div>
    </MenuItem>
  );

  return (
    <>
      <div className={classes.formControl}>
        <TableContainer>
          <Table
            aria-label="simple table"
            size="small"
            className={cn(classes.table, {
              [classes.tableWithSplitHours]: splitHours,
              [classes.tableWithSplitSalary]: splitSalary,
            })}
          >
            <TableHead>
              <TableRow>
                <TableCell>Role</TableCell>
                {needsRateType && <TableCell>RateType</TableCell>}
                <TableCell>Rate/Employee</TableCell>
                {splitHours && <TableCell>Billable hours</TableCell>}
                {splitHours && <TableCell>Absorbed hours</TableCell>}

                {splitSalary ? (
                  <>
                    <TableCell align="right">Billable Salary</TableCell>
                    <TableCell align="right">GT Salary</TableCell>
                  </>
                ) : (
                  <>
                    <TableCell>{`${splitHours ? 'Total hours' : 'Hours'}`}</TableCell>
                    <TableCell align="right">Salary</TableCell>
                  </>
                )}
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {team.map(
                (
                  {
                    id,
                    role,
                    rate,
                    customRate,
                    control,
                    employee,
                    hours: { billable, absorbed, total },
                    billableSalary,
                    gtSalary,
                    salary,
                    rateType,
                  },
                  index,
                ) => {
                  let filteredEmployeesByRole = employees;

                  if (role !== 'Project Lead') {
                    filteredEmployeesByRole = filterByRole(employees, role);
                  }

                  return (
                    <TableRow key={id}>
                      <TableCell>
                        <Select
                          variant="outlined"
                          className={cn(classes.condensed, classes.selectWithRate)}
                          fullWidth
                          value={role}
                          onChange={(event) =>
                            onChangeRole({
                              teamRoleId: id,
                              role: `${event.target.value}`,
                              defaultEmployee: employees[0],
                            })
                          }
                        >
                          {FixBidCalculatorConfig.teamRoles.map(renderTeamMemberRole)}
                        </Select>
                      </TableCell>
                      {needsRateType && (
                        <TableCell>
                          <Tooltip
                            title={rateType === RateType.usBillableHours ? 'US rate' : 'EU rate'}
                            placement="top"
                          >
                            <Switch
                              checked={rateType === RateType.usBillableHours}
                              onChange={({ target: { checked } }) => changeRateType({ teamMemberId: id, checked })}
                              name="rateType"
                              inputProps={{ 'aria-label': 'rate type' }}
                            />
                          </Tooltip>
                        </TableCell>
                      )}
                      <TableCell className={classes.cell}>
                        {control === 'rate' && (
                          <Editable
                            editMode={customRate !== undefined}
                            node={<NumberCell number={rate} />}
                            text={`$${Number(rate)
                              .toFixed(2)
                              .replace(/\\d(?=(\\d{3})+\\.)/g, '$&,')}`}
                            type="input"
                            childRef={references.current[index]}
                          >
                            <NumberFormat
                              ref={(element) => {
                                // @ts-ignore
                                references.current[index] = element;
                              }}
                              allowNegative={false}
                              thousandSeparator
                              fullWidth
                              customInput={TextField}
                              className={cn(classes.condensed, classes.smallWithAddons)}
                              variant="outlined"
                              value={customRate}
                              defaultValue={round(Number(rate), precision)}
                              InputProps={{
                                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                                endAdornment: (
                                  <InputAdornment position="end">
                                    <Tooltip title="Remove custom rate">
                                      <IconButton
                                        aria-label="Remove custom rate"
                                        onClick={() => onRemoveCustomRate({ teamRoleId: id })}
                                      >
                                        <UpdateIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </InputAdornment>
                                ),
                              }}
                              onValueChange={({ floatValue }) =>
                                onChangeCustomRate({ teamRoleId: id, teamRoleRate: floatValue || 0 })
                              }
                            />
                          </Editable>
                        )}
                        {control === 'employeeSelect' && (
                          <>
                            <Autocomplete
                              value={employee ?? null}
                              getOptionSelected={(option, value) => option.id === value.id}
                              classes={{ input: classes.employeeSelect, paper: classes.employeeSelectPaper }}
                              size="small"
                              openOnFocus
                              blurOnSelect
                              options={filteredEmployeesByRole}
                              getOptionLabel={(option) => option.name}
                              renderOption={(option) => {
                                return (
                                  <div className={classes.option}>
                                    <div>{option.name}</div>
                                    <div>
                                      <span title="Average Rate" className={classes.rateTitle}>
                                        {round(option.rate, precision)}
                                      </span>
                                    </div>
                                  </div>
                                );
                              }}
                              renderInput={(parameters) => <TextField {...parameters} variant="outlined" />}
                              onChange={(_event, value) => onChangeEmployee({ teamRoleId: id, employee: value })}
                            />
                          </>
                        )}
                      </TableCell>
                      <TableCell>
                        <NumberFormat
                          customInput={TextField}
                          allowNegative={false}
                          className={cn(classes.condensed, classes.small)}
                          variant="outlined"
                          value={billable}
                          onFocus={(input: { target: { value: string } }) => {
                            if (input.target.value === '0') {
                              input.target.value = '';
                            }
                          }}
                          onValueChange={({ floatValue }) =>
                            onChangeHours({ teamRoleId: id, part: 'billable', hours: Number(floatValue) || 0 })
                          }
                          thousandSeparator
                        />
                      </TableCell>
                      {splitHours && (
                        <>
                          <TableCell>
                            <NumberFormat
                              customInput={TextField}
                              allowNegative={false}
                              className={cn(classes.condensed, classes.small)}
                              variant="outlined"
                              value={absorbed}
                              defaultValue={0}
                              onFocus={(input: { target: { value: string } }) => {
                                if (input.target.value === '0') {
                                  input.target.value = '';
                                }
                              }}
                              onValueChange={({ floatValue }) =>
                                onChangeHours({ teamRoleId: id, part: 'absorbed', hours: Number(floatValue) || 0 })
                              }
                              thousandSeparator
                            />
                          </TableCell>
                          {!splitSalary && (
                            <TableCell align="right" className={classes.cell}>
                              <NumberCell number={total} />
                            </TableCell>
                          )}
                        </>
                      )}
                      {splitSalary ? (
                        <>
                          <TableCell align="right" className={classes.cell}>
                            <SalaryCell salary={billableSalary} />
                          </TableCell>
                          <TableCell align="right" className={classes.cell}>
                            <SalaryCell salary={gtSalary} />
                          </TableCell>
                        </>
                      ) : (
                        <TableCell align="right" className={classes.cell}>
                          <SalaryCell salary={salary} />
                        </TableCell>
                      )}

                      <TableCell align="right" className={classes.cell}>
                        <IconButton
                          size="small"
                          aria-label="delete"
                          onClick={() => onDeleteTeamMember({ teamMemberId: id })}
                          title="Remove Role"
                        >
                          <DeleteIcon fontSize="small" />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  );
                },
              )}
            </TableBody>
            <TableFooter className={classes.footer}>
              <TableRow>
                <TableCell />
                {needsRateType && <TableCell />}
                <TableCell />
                {splitHours && (
                  <>
                    <TableCell align="right" style={billableHoursColorStyle}>
                      {billableHours}
                    </TableCell>
                    <TableCell align="right" style={absorbedHoursColorStyle}>
                      {absorbedHours}
                    </TableCell>
                  </>
                )}
                {splitSalary ? (
                  <>
                    <TableCell align="right">
                      <NumberFormat
                        thousandSeparator
                        value={totalBillableSalary}
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        style={totalSalaryColorStyle}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        thousandSeparator
                        value={totalGTSalary}
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        style={totalSalaryColorStyle}
                      />
                    </TableCell>
                  </>
                ) : (
                  <>
                    <TableCell align="right">
                      <NumberFormat
                        thousandSeparator
                        value={totalHours}
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                        style={totalHoursColorStyle}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <NumberFormat
                        thousandSeparator
                        value={totalSalary}
                        displayType="text"
                        decimalScale={2}
                        fixedDecimalScale
                        prefix="$"
                        style={totalSalaryColorStyle}
                      />
                    </TableCell>
                  </>
                )}

                <TableCell />
              </TableRow>
            </TableFooter>
          </Table>
        </TableContainer>
      </div>
    </>
  );
};
