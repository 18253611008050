import { signOut } from 'next-auth/client';

import { Employee as EmployeeType } from '../types/components/Employee';

export const getDepNameById = (
  departments: { _id: string; name: string }[],
  selectedDepartment?: string,
): string | undefined => departments.find((department) => department._id === selectedDepartment)?.name;

export const ValueTypes = {
  string: 'string',
  number: 'number',
  boolean: 'boolean',
  symbol: 'symbol',
  bigint: 'bigint',
  function: 'function',
  date: 'date',
  object: 'object',
  array: 'array',
  null: 'null',
  undefined: 'undefined',
  unknown: 'unknown',
} as const;

type ValueType = typeof ValueTypes[keyof typeof ValueTypes];

const __getObjectType = (value: object | null): ValueType => {
  if (value === null) {
    return ValueTypes.null;
  }
  if (Array.isArray(value)) {
    return ValueTypes.array;
  }
  if (value instanceof Date) {
    return ValueTypes.date;
  }

  return ValueTypes.object;
};

export const getValueType = (value: unknown): ValueType => {
  switch (typeof value) {
    case 'string':
      return ValueTypes.string;
    case 'number':
      return ValueTypes.number;
    case 'boolean':
      return ValueTypes.boolean;
    case 'function':
      return ValueTypes.function;
    case 'undefined':
      return ValueTypes.undefined;
    case 'symbol':
      return ValueTypes.symbol;
    case 'bigint':
      return ValueTypes.bigint;
    case 'object':
      return __getObjectType(value);
    default:
      return ValueTypes.unknown;
  }
};

export const isEmpty = <T = unknown>(value: unknown): value is undefined | null | '' | {} | T[] => {
  return (
    value === null ||
    value === undefined ||
    (getValueType(value) === ValueTypes.string && value === '') ||
    (getValueType(value) === ValueTypes.object && value === {}) ||
    (getValueType(value) === ValueTypes.array && value === [])
  );
};

export const round = (value: number, precision = 2): number => Math.round(value * 10 ** precision) / 10 ** precision;

export const getLastHistoryValue = (history = {}, currentDate: Date | false = new Date()): number | string => {
  if (isEmpty(history)) {
    return '';
  }

  let dates = Object.keys(history);

  if (currentDate !== false) {
    dates = dates.filter((date) => new Date(date).getTime() <= new Date(currentDate).getTime());
  }
  dates = dates.sort((alpha, beta) => new Date(beta).getTime() - new Date(alpha).getTime());

  if (dates.length === 0) {
    const firstKey = Object.keys(history)[0];

    return history[firstKey];
  }

  return history[dates[0]];
};

export const isNumeric = (value: string): boolean => /^-?\d+(?:\.\d+)?$/.test(`${value}`);

export const isValidDate = (date: unknown): boolean => date instanceof Date && !Number.isNaN(date.getTime());

export const removeTimezone = (date: Date): Date => {
  const offset = -date.getTimezoneOffset();

  date.setHours(Math.trunc(offset / 60), offset % 60);

  return date;
};

export const dateToString = (date: Date | null): string => {
  if (!date) {
    return '';
  }

  return removeTimezone(date).toISOString().split('T')[0];
};

export const floatInputValue = (value: string): string => {
  let i = 0;

  return value.replace(/[^0-9.]/g, '').replace(/[\.]/g, function (match) {
    return match === '.' ? (i++ === 0 ? '.' : '') : '';
  });
};

export const validateEmail = (value: string): boolean => {
  const regEx = /^[\w!#$%&'*+./=?^`{|}~-]+@[\dA-Za-z-]+(?:\.[\dA-Za-z-]+)*$/;

  return regEx.test(value);
};

export const checkNullAndInfinity = (value: number): number => {
  if (Number.isNaN(value) || value === Number.POSITIVE_INFINITY || value === Number.NEGATIVE_INFINITY) {
    return 0;
  }

  return round(value);
};

export const validateRole = (roles: string[] | null | undefined): boolean => {
  const listRolesWithPermission = new Set(['Admin']);
  const isAvailable = !roles ? null : roles.some((item) => listRolesWithPermission.has(item));

  return Boolean(isAvailable);
};

export const forcedSignOut = (status: number): void => {
  if (status === 403) {
    signOut();
  }
};

export const getLatestValue = (object: {} | null | undefined): null | string | number | unknown => {
  if (!object) return null;

  return Object.values(object).slice(-1)[0];
};

export const employeeSorter = (
  sortDirection: 'asc' | 'desc',
  sortValue: keyof EmployeeType,
): ((firstValue: EmployeeType, secondValue: EmployeeType) => 0 | 1 | -1) => {
  if (sortDirection === 'asc') {
    return (firstValue: EmployeeType, secondValue: EmployeeType) => {
      if (firstValue[sortValue] === secondValue[sortValue]) {
        return 0;
      }

      return firstValue[sortValue] > secondValue[sortValue] ? 1 : -1;
    };
  }

  return (firstValue: EmployeeType, secondValue: EmployeeType) =>
    firstValue[sortValue] > secondValue[sortValue] ? -1 : 1;
};
export const changeValueFormat = (value: string | boolean | number | null, sub: string): string | number => {
  if (typeof value === 'boolean') {
    return value ? 'Yes' : 'No';
  }

  if (value === null || ['-', ''].includes(value.toString().trim())) {
    return sub;
  }

  return value;
};
export const registerInsensitiveCollation = { locale: 'en', strength: 2 };
export const getCurrentTime = () => new Date().toString().split('(')[0];
export const formattedDate = (date: string) => new Date(date).toISOString().split('T')[0];

export const projectBillableTypes: { [key: string]: string } = {
  yes: `B`,
  no: 'NB',
  other: 'Other',
};
export const redirectOption = {
  redirect: {
    destination: '/',
    permanent: false,
  },
};
